import { render, staticRenderFns } from "./update-modal.vue?vue&type=template&id=d8b1ac98&scoped=true"
import script from "./update-modal.vue?vue&type=script&lang=js"
export * from "./update-modal.vue?vue&type=script&lang=js"
import style0 from "./update-modal.vue?vue&type=style&index=0&id=d8b1ac98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8b1ac98",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzButton: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/button/index.js').default})
