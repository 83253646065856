/* eslint-disable no-console */
import { mapActions, mapMutations } from 'vuex';
import StorageLock from '@razlet/auth/src/utils/lock';
import { REFRESH, LOGOUT } from '@razlet/auth/src/store/actions.types';
import { USER_CLEAR, USER_SUCCESS, TOKEN_SUCCESS_NO_EVENT } from '@razlet/auth/src/store/mutations.types';
import { GET_USER_AND_BALANCE } from '@razlet/sdk/lib/store/auth/actions.type';
import PushMethods from '@razlet/sdk/lib/methods/push';
import { oldDebounce as debounce } from '@razlet/sdk/lib/utils/helpers';

export default {
  data() {
    return {
      authChannel: null,
      pushSender: new PushMethods(),
      setToken: null,
    };
  },
  computed: {
    isAuthRequired() {
      return process.env.auth === 'required';
    },
    isAuthOptional() {
      return process.env.auth === 'optional';
    },
    isNoAuth() {
      return process.env.auth === 'none';
    },
    isClientSide() {
      return process.client;
    },
    firebaseToken() {
      return this.$store.state.pushToken;
    },
  },
  watch: {
    '$route': {
      async handler(route) {
        if (this.isClientSide && ((this.isAuthRequired && !this.isOffline) || (this.isAuthOptional && await this.$getIsLogged()))) {
          await this.checkAuth(route);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setToken = debounce(this.tokenSuccessNoEvent, 200);
  },
  async mounted() {
    this.$bus.$on('logout', () => {
      this.logout();
    });
    if (this.isApp) {
      window.jivo_onLoadCallback = function () {
        console.log('[JIVO] load', (this.isUser));
        if (this.isUser) {
          // eslint-disable-next-line no-undef
          jivo_destroy();
        }
      };
    }

    if (!this.isNoAuth) {
      this.authChannel = new BroadcastChannel('auth');
      this.authChannel.onmessage = (event) => {
        this.tokenSuccessNoEvent(event.data || null);
        if (event.data) {
          this.userSuccess(this.$jwt.decodeToken(event.data));
          this.postLogin();
        } else {
          this.userClear();
          this.postLogout();
        }
      };

      this.$bus.$on('token-set', async (data) => {
        const { new: newToken, old: oldToken } = data;
        this.getUserInfo();
        if (!oldToken) {
          this.registerPush(newToken);
        }
        else if (this.isUserChanged(oldToken, newToken)) {
          this.switchUserPush(oldToken, newToken);
        }
        this.postToOtherTabs(newToken);
        await this.$setIsLogged(true);
        if (this.isApp) {
          this.setSuluAuth(true);
          this.removeJivo();
        }
        this.$bus.$emit('update-sulu-auth', true);
      });

      this.$bus.$on('token-clear', async (oldToken) => {
        console.log('token-clear');
        this.unregisterPush(oldToken);
        this.postToOtherTabs('');
        await this.$setIsLogged(false);
        if (this.isApp) {
          this.setSuluAuth(false);
          this.showJivo();
        }
        this.$bus.$emit('update-sulu-auth', false);
      });
    }
  },
  beforeDestroy() {
    this.$bus.$off('logout');

    this.$bus.$off('token-set');
    this.$bus.$off('token-clear');

    if (this.authChannel) this.authChannel.close();
  },
  methods: {
    ...mapActions('auth', [GET_USER_AND_BALANCE]),
    ...mapActions([REFRESH, LOGOUT]),
    ...mapActions('account/events', ['getLotteryCoupons']),
    ...mapMutations([USER_SUCCESS, TOKEN_SUCCESS_NO_EVENT, USER_CLEAR, 'setPushRegistered']),
    showJivo() {
      window.jivo_onLoadCallback = function () {

      };
      // eslint-disable-next-line no-undef
      jivo_init();
    },
    removeJivo() {
      window.jivo_onLoadCallback = function () {
        // eslint-disable-next-line no-undef
        jivo_destroy();
      };
      // eslint-disable-next-line no-undef
      jivo_destroy();
    },
    setSuluAuth (value = false) {
      this.$store.commit('sulu/setValue', { key: 'auth', value });
    },
    async postToOtherTabs(value) {
      if (this.authChannel) {
        const sl = new StorageLock('isPosting');
        await sl.lock();
        this.authChannel.postMessage(value);
        sl.unlock();
      }
    },
    isUserChanged(oldValue, newValue) {
      if (!oldValue) return true;

      const oldUser = this.$jwt.decodeToken(oldValue);
      const newUser = this.$jwt.decodeToken(newValue);

      return oldUser.id !== newUser.id || oldUser.contractId !== newUser.contractId;
    },
    getUserInfo() {
      this.getUserAndBalance(this.$store).then(() => {
        this.$bus.$emit('user-balance-loaded');
        this.$ym.setUser(this.userData);
        this.getLotteryCoupons();
      });
    },
    goToLoginPage() {
      if (!this.isNoAuth) this.$router.push(`/login?path=${btoa(this.$route.fullPath)}`);
    },
    registerPush(token) {
      this.$bus.$emit('init-notifications', token);
    },
    switchUserPush(oldToken, newToken) {
      this.pushSender.unregister(oldToken, this.firebaseToken).then(() => {
        this.setPushRegistered(false);
        this.$bus.$emit('reinit-notifications', newToken);
      });
    },
    unregisterPush(token) {
      this.$bus.$emit('stop-notifications');
      return this.pushSender.unregister(token, this.firebaseToken).then(() => {
        this.setPushRegistered(false);
      });
    },
    async checkAuth(route) {
      if (!this.$store.getters.token && !this.$store.getters.loading) {
        const token = await this.$interceptor.intercept();
        if (this.isAuthRequired && !token && route.name !== 'login') this.goToLoginPage();
      }
    },
    logout() {
      this.logoutAction().then(() => {
        this.postLogout();
      });
    },
    postLogin() {
      this.getUserInfo();
      if (this.$route.name === 'login') this.$bus.$emit('redirect-from-login');
    },
    postSwitch() {
      this.getUserInfo();
    },
    postLogout() {
      if (this.isAuthRequired && this.$route.name !== 'login') this.goToLoginPage();
    },
  },
};
