import { USER, AGENT } from '@razlet/sdk/lib/constants/roles';

export default {
  computed: {
    filteredNavItems() {
      if (!this.isUser || !this.user.model.roles) return this.navItems;
      return this.navItems
        .filter(item => this.user.model.roles.includes(item.access) || !item.access)
        .filter(item => item.generals ? item.generals.includes(this.user.model.generalId) : true);
    },
    navItems() {
      return [
        {
          name: this.$t('menu.profile'),
          routeName: 'account-profile',
          access: USER,
        },
        {
          name: this.$t('menu.orders'),
          routeName: 'account-orders',
          access: USER,
        },
        {
          name: this.$t('menu.operations'),
          routeName: 'account-operations',
          access: AGENT,
        },
        {
          name: this.$t('menu.bonuses'),
          routeName: 'account-bonuses',
          access: AGENT,
        },
        {
          name: this.$t('menu.requests'),
          routeName: 'account-requests',
          access: USER,
        },
        {
          name: this.$t('menu.tickets'),
          routeName: 'account-tickets',
          access: USER,
        },
        {
          name: this.$t('menu.passengers'),
          routeName: 'account-passengers',
          access: USER,
        },
        {
          name: this.$t('menu.lottery'),
          routeName: 'account-lottery',
          access: USER,
        },
      ];
    },
  },
};