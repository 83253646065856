
export default {
  name: 'update-modal-app',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    warningTitle: {
      type: String,
      default: '',
    },
    confirmLabel: {
      type: String,
      default: 'Да',
    },
    postponeLabel: {
      type: String,
      default: 'Нет',
    },
    position: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value),
    },
  },

  computed: {
    animationName () {
      if (this.isMobile) return 'slide-bottom';
      return `slide-${this.position}`;
    },
    classes () {
      return {
        [`update-modal-app--${this.position}`]: true,
      };
    },
  },
};
